import * as React from 'react';


export class ISI extends React.PureComponent<any, any> {

    componentDidMount() {
        var divISI = document.getElementById('fixed-isi')
        if (window.location.pathname == "/") {
            if (divISI != null)
                divISI.style.top = "calc(100% - 245px)";
        }
        else {
            if (divISI != null) {
                divISI.style.top = "calc(100% - 32px)";
                divISI.classList.add('isi-top-inactive');
            }
        }
    }

    render() {
        return (
            <div>
                <div id="fixed-isi" className="isi idle loaded" onClick={this.handleToggle}>
                    <div className="banner">
                        <div className="container">
                            <div className="content">
                                <h2>INDICACIÓN E INFORMACIÓN IMPORTANTE DE SEGURIDAD</h2>
                            </div>
                        </div>
                    </div>                    
                    <div className="container">
                        <div className="content">
                            <div className="must-be-visible">
                                <p>
                                    <h3>¿QUÉ ES ORLADEYO<sup>&reg;</sup> (berotralstat)?</h3>
                                    ORLADEYO es un medicamento con receta utilizado para prevenir ataques de angioedema hereditario (AEH) en adultos y niños de 12 años de edad o mayores. No se sabe si ORLADEYO es seguro y eficaz en niños de menos de 12 años de edad.
                                </p>
                                <p>
                                    Se desconoce si ORLADEYO es seguro y eficaz para tratar un ataque de AEH agudo, por lo cual ORLADEYO no debe utilizarse para tratar un ataque agudo de AEH.
                                </p>
                                <p>
                                    No tome más de una cápsula de ORLADEYO al día porque las dosis adicionales pueden causar problemas al ritmo cardíaco.
                                </p>
                                <p>
                                    <h3>INFORMACIÓN IMPORTANTE DE SEGURIDAD</h3>
                                    <strong>Antes de tomar ORLADEYO, hable con su médico acerca de todas las afecciones que es posible que padezca, incluyendo las siguientes:</strong>
                                </p>
                                <p>
                                    <ul>
                                        <li style={{ fontSize: "14px", fontWeight: "normal" }}>tiene problemas hepáticos o está en diálisis renal;</li>
                                        <li style={{ fontSize: "14px", fontWeight: "normal" }}>está embarazada o tiene planes de quedar embarazada. No se sabe si ORLADEYO puede causar daños a un bebé en gestación;</li>
                                        <li style={{ fontSize: "14px", fontWeight: "normal" }}>está amamantando o planea amamantar. No se sabe si ORLADEYO pasa a la leche materna. Hable con su médico sobre la mejor forma de alimentar a su bebé mientras tome ORLADEYO.</li>
                                    </ul>
                                </p>
                                <p><strong>Informe a su médico de todos los medicamentos que tome,</strong> incluidos otros medicamentos para el AEH, los medicamentos de venta con receta y los de venta libre, las vitaminas y los suplementos herbales.</p>
                                <p> Tomar ORLADEYO con ciertos medicamentos puede afectar la manera en que estos funcionan y otros medicamentos pueden afectar la manera en que funciona ORLADEYO.</p>
                                <p>Conozca los medicamentos que toma y haga una lista para enseñársela a su médico y al farmacéutico cuando necesite uno nuevo.</p>
                                <p><strong>¿Cuáles son los posibles efectos secundarios de ORLADEYO?</strong></p><p> Tomar más de una cápsula de ORLADEYO al día puede causar efectos secundarios graves, incluidos <strong>problemas al ritmo cardíaco</strong>. Puede producirse un problema del ritmo cardíaco llamado prolongación del intervalo QT en aquellas personas que tomen más de una cápsula de ORLADEYO al día. Esta afección puede causar latidos cardíacos anómalos. No tome más de una cápsula de ORLADEYO al día.</p>
                                <p>Los efectos secundarios más frecuentes de ORLADEYO incluyen dolor abdominal, vómitos, diarrea, dolor de espalda y acidez. Estos no son todos los posibles efectos secundarios de ORLADEYO. Para obtener más información, hable con su médico o farmacéutico.
                                </p>
                                <p>Hable con su médico para obtener una opinión médica acerca de los efectos secundarios.</p>
                                <p>
                                    <strong>Se sugiere que notifique los efectos secundarios de fármacos con receta a BioCryst Pharmaceuticals, Inc. llamando al 1-833-633-2279 o a la FDA en <a href="https://www.fda.gov/medwatch" rel="noopener" target="_blank">www.fda.gov/medwatch</a> o llamando al 1-800-FDA-1088.<p>Para obtener más información, consulte la <a href="https://biocryst.com/wp-content/uploads/2020/12/ORLADEYO_PI_V1_2020.pdf" rel="noopener" target="_blank">Información de receta completa ,</a> incluida la <a href="https://orladeyo.com/wp-content/uploads/sites/2/ORLADEYO_PI_V1_2020-1.pdf#page=14" rel="noopener" target="_blank">Información para el paciente.</a></p></strong>
                                </p>
                            </div>
                        </div>                       
                    </div>
                </div>
            </div >
        );
    }

    handleToggle = () => {
        var divISI = document.getElementById('fixed-isi')
        if (divISI != null) {
            if (divISI.classList.contains('isi-top-active')) {
                divISI.classList.remove('isi-top-active');
                divISI.classList.remove('active');
                divISI.classList.add('isi-top-inactive');
            }
            else {
                divISI.classList.add('isi-top-active');
                divISI.classList.add('active');
                divISI.classList.remove('isi-top-inactive');
            }
        }
        var divregisterContainer = document.getElementById('registerContainer');
        if (divregisterContainer != null) {
            divregisterContainer.style.height = 'auto';
        }
        //Home page height
        var divHomeHero = document.getElementById('home-hero')
        if (divHomeHero != null) {
            divHomeHero.style.height = "auto";
            divHomeHero.style.paddingBottom = '0px';
        }
        //Confirmation page height
        var divConfirmationContainer = document.getElementById('divConfirmationContainer')
        if (divConfirmationContainer != null) {
            divConfirmationContainer.style.height = "auto";
        }

    }

};
